import React from 'react'

// Import Components
import { LeaveBehindOffers } from '@jelly/templates'

const DEFAULT_RFQ_CAT_ID = '10230'

const META = {
	canonical: `${process.env.GATSBY_SITE_HOST}/lp/compare-gps-fleet-tracking/offers/`,
	description:
		'We rate and rank the leading GPS Fleet Tracking Services to help make your decision-making process easier. See up-to-date comparisons and costs for our top-rated picks.',
	robots: 'noindex, nofollow',
	title: 'Our Top GPS Fleet Tracking Services of 2021 – business.com',
}

const TITLE = 'Compare our Best GPS Fleet Tracking Services'

export const SERVICES = [
	{
		id: '1',
		name: 'Samsara',
		image: 'https://www.business.com/images/qt/28-441-2020-11-10-09-03.svg',
		cardTitle: 'Our Choice for Small Businesses',
		url:
			'https://businesscom.go2cloud.org/aff_c?offer_id=441&aff_id=1068&source=gps-fleet-tracking&aff_sub=bestpick|0&aff_sub2=lineuprank|0&aff_sub3=deal|samsara&aff_sub4=revenue|QT&aff_sub5=pos|ren-[marketingId]&url_id=1',
		bullets: ['Share live tracking with customers', 'Improve fleet productivity', 'Protect your assets from theft'],
		rating: '4.9',
		rfqCatId: DEFAULT_RFQ_CAT_ID,
	},
	{
		id: '2',
		name: 'Azuga',
		image: 'https://www.business.com/images/rz/5fa5a3409c658abc1c8b4569/azuga1_logo.png',
		cardTitle: 'Our Choice for Ease of Use',
		url:
			'https://businesscom.go2cloud.org/aff_c?offer_id=434&aff_id=1068&source=gps-fleet-tracking&aff_sub=bestpick|0&aff_sub2=lineuprank|0&aff_sub3=deal|azuga-inc&aff_sub4=revenue|QT&aff_sub5=pos|ren-[marketingId]&url_id=1',
		bullets: [
			'Easy-to-use system and DIY installation',
			'Total end-to-end safety management',
			'Real-time fleet tracking and monitoring',
		],
		rating: '4.7',
		rfqCatId: DEFAULT_RFQ_CAT_ID,
	},
	{
		id: '3',
		name: 'ClearPathGPS',
		image: 'https://www.business.com/images/rz/61142a275a215eee708b4567/clearpath_logo.png',
		cardTitle: 'Our Choice for Affordability',
		url:
			'https://businesscom.go2cloud.org/aff_c?offer_id=435&aff_id=1068&source=gps-fleet-tracking&aff_sub=bestpick|0&aff_sub2=lineuprank|0&aff_sub3=deal|clearpathgps-inc&aff_sub4=revenue|QT&aff_sub5=pos|ren-[marketingId]&url_id=1',
		bullets: ['30-day money back guarantee', 'No long-term contracts', 'Top-rated U.S. based support'],
		rating: '4.5',
		rfqCatId: DEFAULT_RFQ_CAT_ID,
	},
]

function LeaveBehindOfferPage() {
	return <LeaveBehindOffers meta={META} services={SERVICES} title={TITLE} />
}
export default LeaveBehindOfferPage
